/* eslint-disable camelcase */
<template lang="pug">
  div
    TableV2(
      :headers="headers"
      :items="items.results.data"
      :pageCount="items.count"
      :currentPage="items.current"
      :querySearch="getDataReport"
      :isLoading="isLoading"
      :actions="actions"
      isHiddenTableSearch
      :itemRowBackground="itemRowBackground"

    )
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    search: { type: String, default: '' }
  },
  data () {
    return {
      headers: [
        { value: 'level_qualification.name_ukr', text: this.$t('qualification'), sortable: false },
        { value: 'count', text: this.$t('itemsAmount'), sortable: false },
        { value: 'distribution_sum', text: this.$i18n.t('distribution') },
        { value: 'profit_sum', text: this.$i18n.t('profit') },
        { value: 'event', text: this.$t('go'), sortable: false }
      ],
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.go',
          action: (item) => this.$router.push({ path: `${this.$route.path}/sailor/${item.level_qualification.id}`, query: { search: this.$route.query.search } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: (item) => !item.isView
        }
      ],
      items: { results: {} },
      isLoading: true
    }
  },
  mounted () {
    // this.getDataReport()
  },
  methods: {
    ...mapActions(['getReportDistributionQualification']),
    async getDataReport (data) {
      let params = data.search + `&&educational_institution=${this.$route.params.educational_institution}`
      this.$route.query.search = params
      const response = await this.getReportDistributionQualification({ params })
      let wordArray = ['global', 'page']
      wordArray.forEach(el => {
        let item = {
          profit_sum: response.data.results[`${el}_form2_profit`],
          distribution_sum: response.data.results[`${el}_form2_distribution`],
          count: el === 'page' ? response.data.results[`${el}_count_sum`] : response.data.results[`${el}_sum_count`],
          level_qualification: {
            name_ukr: el === 'page' ? this.$t('totalOnPage') : this.$t('totalGlobal')
          },
          isView: true
        }
        response.data.results.data.unshift(item)
      })
      this.items = response.data
      this.isLoading = false
    },
    itemRowBackground (item) {
      if (item.level_qualification.name_ukr === this.$t('totalOnPage')) return 'table-primary'
      else if (item.level_qualification.name_ukr === this.$t('totalGlobal')) return 'table-success'
      return ''
    }
  }
}
</script>
